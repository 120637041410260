import styled, { css } from "styled-components";

import { mobile, tablet } from "@sellernote/shared/src/styles/functions";
import { COLOR } from "@sellernote/shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/shared/src/stylesToMoveToV1/typography";

const wrapper = styled.div`
  position: relative;

  ${mobile(css`
    overflow-x: scroll;
  `)}
`;

const tabList = styled.ul`
  display: flex;
  width: fit-content;

  ${tablet(css`
    display: inline-block;
    white-space: nowrap;
  `)};

  li {
    height: 40px;
    border-bottom: 1px solid ${COLOR.grayScale_300};
    ${setFontStyle("SubHead3")};
    padding: 8px 16px 10px 16px;
    color: ${COLOR.grayScale_600};
    cursor: pointer;

    ${tablet(css`
      display: inline-block;
    `)}
  }

  li:hover {
    border-bottom: 2px solid ${COLOR.primaryBlue_60};
  }

  li:hover {
    border-bottom: 2px solid ${COLOR.primaryBlue_60};
  }

  .active {
    ${setFontStyle("SubHead3", "Bold")};
    border-bottom: 2px solid ${COLOR.primaryBlue};
    color: ${COLOR.primaryBlue};
  }
`;

export default {
  wrapper,
  tabList,
};
