/* eslint-disable unused-imports/no-unused-imports */
import styled, { css } from "styled-components";

import { COLOR, TEXT_COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";
import { setFontStyle } from "@sellernote/shared/src/sds-v2/styles/typography";

const container = styled.div``;

const header = styled.div`
  display: flex;
  justify-content: space-between;

  > .updated-at {
    ${setFontStyle("SubHead3")};
    color: ${TEXT_COLOR.black_2};
  }
`;

const tableContainer = styled.div`
  em.confirmed-charge {
    color: ${COLOR.primary_600};
  }

  span.container-type {
    display: block;
    ${setFontStyle("Body3")};
    color: ${TEXT_COLOR.black_3};
  }

  .exceeded-duration {
    color: ${COLOR.point_400};
  }
`;

const errorGuideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 160px;
  height: calc(100% - 32px);
  ${setFontStyle("SubHead2")};
  gap: 6px;
  text-align: center;

  .error-message {
    color: ${TEXT_COLOR.black_3};
  }
`;

export default {
  container,
  header,
  tableContainer,
  errorGuideContainer,
};
