import { useTranslation } from "react-i18next";

import EmptyRequestIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/EmptyRequestIcon";
import { COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";

import { getFreeTimeErrorCodeToMessage } from "../../utils/forwarding/error/freeTime";

import { FreeTimeError } from "../../api-interfaces/shipda-api/freeTime";
import Styled from "./index.styles";

function ErrorGuide({ errorInfo }: { errorInfo?: FreeTimeError }) {
  const { t } = useTranslation();

  const { errorCode, error } = errorInfo ?? {};

  return (
    <Styled.errorGuideContainer>
      <EmptyRequestIcon width={32} height={32} color={COLOR.grayScale_600} />

      <span className="error-message">
        {getFreeTimeErrorCodeToMessage({ errorCode, error, t })}
      </span>
    </Styled.errorGuideContainer>
  );
}

export default ErrorGuide;
